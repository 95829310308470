import $ from "jquery";
import "slick-carousel";

const initImageSlider = () => {
  if ($(".js-image-slider").length) {
    $(".js-image-slider")
      .not(".slick-initialized")
      .slick({
        arrows: false,
        dots: true,
        autoplay: true,
      })
      .on("afterChange", function (event, slick, currentSlide) {
        const caption = $(".js-image-slider-captions").find(
          `[data-index="${currentSlide + 1}"]`,
        );

        $(".js-image-slider-captions")
          .find(".is-active")
          .removeClass("is-active");

        caption.addClass("is-active");
      });

    const pauseSliderButton = $(".js-pause-slider");

    pauseSliderButton.on("click", function () {
      if ($(this).hasClass("is-paused")) {
        $(this).removeClass("is-paused");
        $(".js-image-slider").slick("slickPlay");
      } else {
        $(this).addClass("is-paused");
        $(".js-image-slider").slick("slickPause");
      }
    });
  }
};

$(function () {
  initImageSlider();
});

if (window.acf) {
  window.acf.addAction("render_block_preview/type=hero", function () {
    initImageSlider();
  });
}
